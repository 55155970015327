dmx.Component('swiper', {

  initialData: {
    index: 0,
    total: 0,
    isBeginning: false,
    isEnd: false,
  },

  attributes: {
    // General

    vertical: {
      type: Boolean,
      default: false,
    },

    speed: {
      type: Number,
      default: 300, // Duration of transition between slides (in ms)
    },

    autoHeight: {
      type: Boolean,
      default: false,
    },

    effect: {
      type: String,
      default: 'slide', // slide, fade, cube, coverflow, flip, cards, creative
    },

    initialSlide: {
      type: Number,
      default: 0,
    },
    // Slides Grid

    spaceBetween: {
      type: Number,
      default: 0,
    },

    slidesPerView: {
      type: Number,
      default: 1,
    },

    slidesPerGroup: {
      type: Number,
      default: 1,
    },

    spaceBetweenSm: {
      type: Number,
      default: undefined,
    },

    slidesPerViewSm: {
      type: Number,
      default: undefined,
    },

    slidesPerGroupSm: {
      type: Number,
      default: undefined,
    },

    spaceBetweenMd: {
      type: Number,
      default: undefined,
    },

    slidesPerViewMd: {
      type: Number,
      default: undefined,
    },

    slidesPerGroupMd: {
      type: Number,
      default: undefined,
    },

    spaceBetweenLg: {
      type: Number,
      default: undefined,
    },

    slidesPerViewLg: {
      type: Number,
      default: undefined,
    },

    slidesPerGroupLg: {
      type: Number,
      default: undefined,
    },

    spaceBetweenXl: {
      type: Number,
      default: undefined,
    },

    slidesPerViewXl: {
      type: Number,
      default: undefined,
    },

    slidesPerGroupXl: {
      type: Number,
      default: undefined,
    },

    centeredSlides: {
      type: Boolean,
      default: false,
    },

    slidesOffsetBefore: {
      type: Number,
      default: 0,
    },

    slidesOffsetAfter: {
      type: Number,
      default: 0,
    },

    // Grab Cursor

    grabCursor: {
      type: Boolean,
      default: false,
    },

    // Freemode

    freeMode: {
      type: Boolean,
      default: false,
    },

    freeModeSticky: {
      type: Boolean,
      default: false,
    },

    // Loop

    loop: {
      type: Boolean,
      default: false,
    },

    // Components

    pagination: {
      type: String,
      default: undefined, // bullets, fraction, progressbar
    },

    navigation: {
      type: Boolean,
      default: false,
    },

    scrollbar: {
      type: Boolean,
      default: false,
    },

    autoplay: {
      type: Boolean,
      default: false,
    },

    parallax: {
      type: Boolean,
      default: false,
    },

    keyboard: {
      type: Boolean,
      default: false,
    },

    mousewheel: {
      type: Boolean,
      default: false,
    },

    observer: {
      type: Boolean,
      default: false,
    },

    dynamicBullets: {
      type: Boolean,
      default: false,
    },

    rewind: {
      type: Boolean,
      default: false,
    },

    hashNavigation: {
      type: Boolean,
      default: false,
    },

    watchState: {
      type: Boolean,
      default: false,
    },

    // Dynamic Data

    slides: {
      type: [Array, Object, Number],
      default: false,
    },
  },

  methods: {
    slideNext (speed, runCallbacks) {
      this._swiper.slideNext(speed, runCallbacks);
    },

    slidePrev (speed, runCallbacks) {
      this._swiper.slidePrev(speed, runCallbacks);
    },

    slideTo (index, speed, runCallbacks) {
      this._swiper.slideTo(index, speed, runCallbacks);
    },

    slideToLoop (index, speed, runCallbacks) {
      this._swiper.slideToLoop(index, speed, runCallbacks);
    },

    slideReset (speed, runCallbacks) {
      this._swiper.slideReset(speed, runCallbacks);
    },

    slideToClosest (speed, runCallbacks) {
      this._swiper.slideToClosest(speed, runCallbacks);
    },

    autoplayStart () {
      this._swiper.autoplay.start();
    },

    autoplayStop () {
      this._swiper.autoplay.stop();
    },

    update () {
      this._update();
    },
  },

  events: {
    change: Event,
    rendered: Event,
  },

  init (node) {
    this._dynamic = node.hasAttribute('dmx-bind:slides');
    this._updateData = this._updateData.bind(this);
  },

  render (node) {
    this._template = node.innerHTML;

    this._container = node;
    this._container.classList.add('swiper');
    this._container.innerHTML = '';

    this._wrapper = document.createElement('div');
    this._wrapper.className = 'swiper-wrapper';

    this._pagination = document.createElement('div');
    this._pagination.className = 'swiper-pagination';

    this._buttonPrev = document.createElement('div');
    this._buttonPrev.className = 'swiper-button-prev';

    this._buttonNext = document.createElement('div');
    this._buttonNext.className = 'swiper-button-next';

    this._scrollbar = document.createElement('div');
    this._scrollbar.className = 'swiper-scrollbar';

    this._container.appendChild(this._wrapper);

    if (this.props.pagination) {
      this._container.appendChild(this._pagination);
    }

    if (this.props.navigation) {
      this._container.appendChild(this._buttonPrev);
      this._container.appendChild(this._buttonNext);
    }

    if (this.props.scrollbar) {
      this._container.appendChild(this._scrollbar);
    }

    if (!this._dynamic) {
      this._wrapper.innerHTML = this._template;
      this.$parse(this._wrapper);
    } else {
      this._renderChildren();
    }

    this._update();
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('slides')) {
      this._renderChildren();
    }

    if (updatedProps.has('pagination')) {
      if (this.props.pagination) {
        this._container.appendChild(this._pagination);
      } else {
        this._pagination.remove();
      }
    }

    if (updatedProps.has('navigation')) {
      if (this.props.navigation) {
        this._container.appendChild(this._buttonPrev);
        this._container.appendChild(this._buttonNext);
      } else {
        this._buttonPrev.remove();
        this._buttonNext.remove();
      }
    }

    if (updatedProps.has('scrollbar')) {
      if (this.props.scrollbar) {
        this._container.appendChild(this._scrollbar);
      } else {
        this._scrollbar.remove();
      }
    }

    this._update();
  },

  destroy () {
    if (this._swiper) {
      this._swiper.destroy();
    }
  },

  _renderChildren () {
    this._wrapper.innerHTML = '';
    this.$destroyChildren();

    const slides = dmx.repeatItems(this.props.slides);

    if (slides.length) {
      const template = document.createElement('div');
      template.innerHTML = this._template;

      const RepeatItem = dmx.Component('repeat-item');
      const fragment = document.createDocumentFragment();

      for (let i = 0; i < slides.length; i++) {
        const child = new RepeatItem(template.cloneNode(true), this, slides[i]);
        child.$nodes.forEach(node => {
          fragment.appendChild(node);
          child.$parse(node);
        });
        this.children.push(child);
      }

      this._wrapper.appendChild(fragment);
    }
  },

  _update () {
    if (this._swiper) this._swiper.destroy();

    const params = {
      direction: this.props.vertical ? 'vertical' : 'horizontal',
      speed: this.props.speed,
      autoHeight: this.props.autoHeight,
      effect: this.props.effect,
      spaceBetween: this.props.spaceBetween,
      slidesPerView: this.props.slidesPerView,
      slidesPerGroup: this.props.slidesPerGroup,
      centeredSlides: this.props.centeredSlides,
      slidesOffsetBefore: this.props.slidesOffsetBefore,
      slidesOffsetAfter: this.props.slidesOffsetAfter,
      grabCursor: this.props.grabCursor,
      freeMode: {
        enabled: this.props.freeMode,
        sticky: this.props.freeModeSticky,
      },
      loop: this.props.loop,
      loopFillGroupWithBlank: this.props.loopFillGroupWithBlank,
      pagination: {
        el: this.props.pagination ? '.swiper-pagination' : null,
        type: this.props.pagination,
        clickable: true,
        dynamicBullets: this.props.dynamicBullets,
      },
      navigation: {
        prevEl: this.props.navigation ? '.swiper-button-prev' : null,
        nextEl: this.props.navigation ? '.swiper-button-next' : null,
      },
      scrollbar: {
        el: this.props.scrollbar ? '.swiper-scrollbar' : null,
        draggable: true,
        snapOnRelease: true,
      },
      autoplay: this.props.autoplay,
      parallax: this.props.parallax,
      keyboard: this.props.keyboard,
      mousewheel: this.props.mousewheel,
      breakpointsInverse: true,
      breakpoints: {},
      observer: this.props.observer,
      observeParents: this.props.observer,
      rewind: this.props.rewind,
      hashNavigation: {
        enabled: this.props.hashNavigation,
        watchState: this.props.watchState,
      },
      initialSlide: this.props.initialSlide,
      eventsPrefix: '',
    };

    this._spaceBetween = this.props.spaceBetween;
    this._slidesPerView = this.props.slidesPerView;
    this._slidesPerGroup = this.props.slidesPerGroup;

    params.breakpoints[576] = this._getBreakpointProps('Sm');
    params.breakpoints[768] = this._getBreakpointProps('Md');
    params.breakpoints[992] = this._getBreakpointProps('Lg');
    params.breakpoints[1200] = this._getBreakpointProps('Xl');

    if (window.__WAPPLER__) {
      params.simulateTouch = false;
    }

    this._swiper = new Swiper(this._container, params);
    this._swiper.on('realIndexChange', this._updateData);
    this._swiper.on('observerUpdate', this._updateData);
    this._swiper.on('slideChange', this._updateData);
    this._swiper.on('slideChange', this.dispatchEvent.bind(this, 'change'));
    
    this._updateData();

    this.dispatchEvent('rendered');

    requestAnimationFrame(() => {
      this._swiper.updateSize();
    });
  },

  _getBreakpointProps (suffix) {
    if (this.props['spaceBetween' + suffix] != null) {
      this._spaceBetween = this.props['spaceBetween' + suffix];
    }

    if (this.props['slidesPerView' + suffix] != null) {
      this._slidesPerView = this.props['slidesPerView' + suffix];
    }

    if (this.props['slidesPerGroup' + suffix] != null) {
      this._slidesPerGroup = this.props['slidesPerGroup' + suffix];
    }

    return {
      spaceBetween: this._spaceBetween,
      slidesPerView: this._slidesPerView,
      slidesPerGroup: this._slidesPerGroup,
    };
  },

  _updateData: function () {
    if (this._swiper && !this._swiper.destroyed) {
      var total = this._swiper.slides.length;

      this.set('index', total > 0 ? this._swiper.realIndex % total : 0);
      this.set('total', total);
      this.set('isBeginning', this._swiper.isBeginning);
      this.set('isEnd', this._swiper.isEnd);
    }
  },
});
